/*global gtag*/
import React from "react";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

import github from "../assets/images/icons/github-black.png";
import cover from "../assets/images/cover.jpg";
import snowflake from "../assets/images/snowflake.svg";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51HYIEyJrogc7R8yFyqErQe6utJ9A02981Jz8hB2fEUV54Oz9pITbhPTT4WBwRbetrQ6TvVWZ3kTtnF62AOadgTI700VzLJFYfd"
);

class Offer extends React.Component {
  constructor(props) {
    super(props);
    // this.product = this.props.location.state.product;
    this.handleClick = this.handleClick.bind(this);
  }

  async handleClick(e, product) {
    if (
      document.location.hostname.search(
        "www.essentialmathfordatascience.com"
      ) !== -1
    ) {
      gtag("event", "click", {
        event_category: "get_product",
        event_label: "product",
        value: product,
      });
    }

    // const stripe = await stripePromise;

    // const response = await fetch(`https://s7l8b6e7j5.execute-api.us-east-1.amazonaws.com/dev/${product}`,
    //   { method: 'GET' });

    // const session = await response.json();
    // // When the customer clicks on the button, redirect them to Checkout.
    //   const result = await stripe.redirectToCheckout({
    //     sessionId: session.id,
    //   });
    //   if (result.error) {
    //     console.log('ERR', result.error.message)
    //     // If `redirectToCheckout` fails due to a browser or network
    //     // error, display the localized error message to your customer
    //     // using `result.error.message`.
    //   }
  }

  render() {
    return (
      <Container>
        <div
          className="mx-auto text-center offer"
          style={{ position: "relative" }}
        >
          <div className="offer-title">
            <p>Buy before</p>

            <p>
              <span style={{ color: "#f87188" }}>29 March 2024</span>
            </p>
            <p>and benefit from a great reduction!</p>
            <p>
              Use the offer code <span className="highlight">MAR2024</span>
            </p>
          </div>

          {/*           <div className="offer-subtitle">New year's resolution: upskill in data
            science and machine learning!</div>*/}

          <CardDeck className="mx-auto offer-deck">
            <Card className="shadow p-3 mb-5 bg-white offer-card">
              <h2 className="card-title">The Book</h2>

              <div className="text-center card-img">
                <img
                  className="cover-shadow"
                  src={cover}
                  width="25%"
                  alt="cover"
                />
              </div>

              <div className="text-center offer-text">
                You get the book in various electronic versions
              </div>

              <Card.Body style={{ position: "relative" }}>
                <Card.Text className="text-left">
                  <svg
                    width="2.5em"
                    height="2.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-check"
                    fill="#00E64E"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                  PDF/EPUB
                </Card.Text>
              </Card.Body>
              <div className="price">
                <p>€40</p>
                <p>
                  €25 with the code <span className="highlight">MAR2024</span>!
                </p>
              </div>
              <a
                className="link-gumroad"
                href="https://gum.co/wyBbL/?wanted=true"
                data-gumroad-single-product="true"
              >
                <Card.Footer onClick={(e) => this.handleClick(e, "book")}>
                  <div>GET</div>
                </Card.Footer>
              </a>
            </Card>
            <Card className="shadow p-3 mb-5 bg-white offer-card">
              <h2 className="card-title">Complete Code</h2>

              <div className="card-img">
                <img
                  className="cover-shadow"
                  src={cover}
                  width="25%"
                  alt="cover"
                />
                <div class="plus-sign">+</div>
                <img src={github} alt="" width="30%" title="github" />
              </div>

              <div className="text-center offer-text">
                You get the book and access to the private repo with all the
                code
              </div>

              <Card.Body>
                <Card.Text className="text-left">
                  <svg
                    width="2.5em"
                    height="2.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-check"
                    fill="#00E64E"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                  PDF/EPUB
                  <br />
                  <svg
                    width="2.5em"
                    height="2.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-check"
                    fill="#00E64E"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                  Access to the private repo with the complete code (notebooks)
                  <br />
                  <svg
                    width="2.5em"
                    height="2.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-check"
                    fill="#00E64E"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                  Ask your questions as issues in the repo
                </Card.Text>
              </Card.Body>
              <div className="price">
                <p>€50</p>
                <p>
                  €35 with the code <span className="highlight">MAR2024</span>!
                </p>
              </div>
              <a
                className="link-gumroad"
                href="https://gum.co/ZcUPU?wanted=true"
                target="_blank"
                data-gumroad-single-product="true"
              >
                <Card.Footer onClick={(e) => this.handleClick(e, "book-plus")}>
                  <div>GET</div>
                </Card.Footer>
              </a>
            </Card>
          </CardDeck>
        </div>
      </Container>
    );
  }
}

export default Offer;
